/* @font-face {
    font-weight: 400;
    font-family: 'SVN-Circular';
    src: local('SVN-Circular'), url(../../public/fonts/svn-circular/SVN-Circular-Regular.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-weight: 500;
    font-family: 'SVN-Circular';
    src: local('SVN-Circular'), url(../../public/fonts/svn-circular/SVN-Circular-Medium.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-weight: 700;
    font-family: 'GoogleSans';
    src: local('GoogleSans'), url(../../public/fonts/svn-circular/SVN-Circular-Bold.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-weight: 400;
    font-family: 'GoogleSans';
    src: local('GoogleSans'), url(../../public/fonts/google-sans/GoogleSans-Regular.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-weight: 500;
    font-family: 'GoogleSans';
    src: local('GoogleSans'), url(../../public/fonts/google-sans/GoogleSans-Medium.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-weight: 700;
    font-family: 'SVN-Circular';
    src: local('SVN-Circular'), url(../../public/fonts/google-sans/GoogleSans-Bold.ttf) format('truetype');
    font-display: swap;
} */

html {
    margin: 0 !important;
    padding: 0 !important;
    scroll-behavior: smooth;
    height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    font-size: 14px;
    min-height: 100vh;
}

body {
    margin: 0 !important;
    padding: 0 !important;
    height: 100vh;
    min-height: 100vh;
}

#__next {
    height: 100vh;
    min-height: 100vh;
}


/* react-transition-group */

.item-enter {
    opacity: 0;
    transition: 500ms ease-in;
}

.item-enter-active {
    opacity: 1;
    transition: 500ms ease-in;
}

.item-exit {
    opacity: 1;
    transition: 500ms ease-out;
}

.item-exit-active {
    opacity: 0;
    transition: 500ms ease-out;
}
.image-next-seo {
    width: 100%;
    height: 100%;
    position: relative !important;
}
.image-logo {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
.image-container-style {
  height: 100%;
  top: 0px;
  left: 0px;
  width: 100%;
  position: absolute;
}

.color--medium-sub {
    color: #858585 !important;
}
.color--light-disable {
    color: #B8B8B8 !important;
}
.bg--lightest-bg {
    background-color: rgb(247, 247, 247) !important;
}
.color--medium-sub {
    color: #858585 !important;
}

.color--darkness {
  color: #141414 !important;
}
.bg--vex-d-blue {
    background-color: #061B37 !important;
}
.color--white {
    color: #ffffff !important;
}
q::after {
    content: none;
}
q::before {
    content: none;
}

* { 
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}